import React from 'react';
import {NavItems} from './';

import './Nav.scss';

export const Nav = () => {
  return (
    <nav className="nav">
      <NavItems />
    </nav>
  );
};
