import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import './Contact.scss';
import {setExpandFooter} from '../../store/app/events';

export const Contact = () => {
  const {slug} = useParams();
  useEffect(() => {
    setExpandFooter(true);
  }, []);

  // const {data, error} = useAxios(`${config.apiURL}/pages/${slug}`, 'GET', null, slug);

  // useEffect(() => {
  //   setTomeas(data);
  // }, [slug, data]);

  return <></>;
};
