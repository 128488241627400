import {useStore} from 'effector-react';
import {setNavIsOpen} from '../../../store/app/events';
import $appStore from '../../../store/app/store';
import './BurgerMenu.scss';

export const BurgerMenu = () => {
  const appStore = useStore($appStore);

  return (
    <div className="burgerMenu">
      <div
        className={`burgerMenu-icon ${appStore.navIsOpen ? 'isOpened' : ''}`}
        onClick={() => setNavIsOpen(!appStore.navIsOpen)}
      >
        <div></div>
      </div>
    </div>
  );
};
