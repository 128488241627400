import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import {setDescription, setExpandFooter, setTitle} from '../../store/app/events';
import './Page.scss';

export const Page = () => {
  const {slug} = useParams();
  const {data, error} = useAxios(`${config.apiURL}/pages/${slug}`, 'GET', null, slug);
  const [tomeas, setTomeas] = useState<any>();

  useEffect(() => {
    setTomeas(data);
  }, [slug, data]);

  useEffect(() => {
    setExpandFooter(false);
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.content.metaTitle);
      setDescription(data.content.metaDescription);
    }
  }, [data]);

  return (
    <div className="page fadeIn">
      {data && slug && (
        <div className="container">
          <div className="inner">
            <h1>{data.content.title} </h1>
            <div className="text" dangerouslySetInnerHTML={{__html: data.content.text}} />
          </div>
        </div>
      )}
    </div>
  );
};
