import {Link} from 'react-router-dom';
import {sanitizeUppercase} from '../../../utils/utils';
import './TomeisSection.scss';

interface ITomeisSection {
  data: any;
}

export const TomeisSection = (props: ITomeisSection) => {
  const {data} = props;
  return (
    <div className="tomeisSection">
      <div className="container">
        <h2 className="tomeisSection-title">{sanitizeUppercase('Τομείς Δικαίου')}</h2>
        <div className="tomeis">
          {data.map((item: any, index: number) => (
            <Link to={`/tomeis-dikaiou/${item.slug}`} className="tomeis-item" key={index}>
              <div className="tomeis-item-inner">
                <div className="tomeis-item-title">{item.title}</div>
                <div className="tomeis-item-text" dangerouslySetInnerHTML={{__html: item.shortText}}></div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
