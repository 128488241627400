import {Route, Routes} from 'react-router';
import {HelmetProvider} from 'react-helmet-async';

import ScrollToTop from './utils/ScrollToTop';

import {Layout, Seo, CacheBuster} from './components';

import {Home, Biografiko} from './pages';

import {ReactComponent as UISvg} from './assets/ui.svg';

import './styles/main.scss';
import {Page} from './pages/Page';
import {Tomeis} from './pages/Tomeis/Tomeis';
import {Contact} from './pages/Contact';

function App() {
  return (
    <CacheBuster>
      <HelmetProvider>
        <div className="App">
          <Seo />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path={'/'} element={<Home />} />
              <Route path={`/biografiko`} element={<Biografiko />} />
              <Route path={`/tomeis-dikaiou`} element={<Tomeis />} />
              <Route path={`/tomeis-dikaiou/:slug`} element={<Tomeis />} />
              <Route path={`/epikoinwnia`} element={<Contact />} />
              <Route path={`/page/:slug`} element={<Page />} />
            </Route>
          </Routes>
          <UISvg />
        </div>
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;
