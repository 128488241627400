import React, {useEffect} from 'react';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {setDescription, setExpandFooter, setTitle} from '../../store/app/events';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import {Hero} from './components/Hero';
import {TomeisSection} from './components/TomeisSection';
import {BiografikoSection} from './components/BiografikoSection';

import './Home.scss';

export const Home = () => {
  const appStore = useStore($appStore);
  const {data, error} = useAxios(`${config.apiURL}/home`, 'POST', {language: 'el'});

  useEffect(() => {
    setExpandFooter(false);
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.meta.metaTitle);
      setDescription(data.meta.metaDescription);
    }
  }, [data]);

  return (
    <div className="home">
      {data && <Hero data={data.hero} />}
      {data && <BiografikoSection data={data.about} />}
      {data && <TomeisSection data={data.tomeis} />}
    </div>
  );
};
