import React, {useEffect} from 'react';
import {setDescription, setExpandFooter, setTitle} from '../../store/app/events';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import './Biografiko.scss';

export const Biografiko = () => {
  const {data, error} = useAxios(`${config.apiURL}/about`, 'POST', {language: 'el'});

  useEffect(() => {
    setExpandFooter(false);
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.metaTitle);
      setDescription(data.metaDescription);
    }
  }, [data]);

  return (
    <div className="page fadeIn">
      {data && (
        <div className="container">
          <div className="inner">
            <h1>{data.title} </h1>
            <div className="text" dangerouslySetInnerHTML={{__html: data.text}} />
          </div>
        </div>
      )}
    </div>
  );
};
