import {useStore} from 'effector-react';
import {Helmet} from 'react-helmet-async';
import $appStore from '../../store/app/store';

export const Seo = () => {
  const appStore = useStore($appStore);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{appStore.siteTitle}</title>
      {appStore.siteDescription && <meta name="description" content={appStore.siteDescription} />}
    </Helmet>
  );
};
