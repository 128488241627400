import {Link} from 'react-router-dom';
import {sanitizeUppercase} from '../../../utils/utils';
import {useAxios} from '../../../hooks/useAxios';
import config from '../../../config/config.json';

export const NavItems = () => {
  const {data, error} = useAxios(`${config.apiURL}cached/navigation.json`, 'get', {language: 'el'});

  return (
    <div className="navItemsInner">
      {data && (
        <div className="navItems">
          {data.navigation.map((item: any, i: number) =>
            item.items === null ? (
              <Link key={i} to={`${item.link}`} title={item.name} className={`navItems-item`}>
                {sanitizeUppercase(item.name)}
              </Link>
            ) : (
              <div className={`navItems-item`} key={i}>
                {sanitizeUppercase(item.name)}
                <div className="navItems-item-submenu">
                  {item.items.length > 0 &&
                    item.items.map((item: any, j: number) => (
                      <Link
                        to={item.link}
                        className="navItems-item-submenu-item"
                        title={`Τομείς Δικαίου: ${item.name}`}
                        key={j}
                      >
                        {item.name}
                      </Link>
                    ))}
                </div>
              </div>
            ),
          )}
          {/* <div className={`navItems-item`}>
            {sanitizeUppercase('Τομείς δικαίου')}
            <div className="navItems-item-submenu">
              <div className="navItems-item-submenu-item">1</div>
              <div className="navItems-item-submenu-item">2</div>
              <div className="navItems-item-submenu-item">3</div>
              <div className="navItems-item-submenu-item">4</div>
            </div>
          </div>
          <Link to={`/epikoinonia`} className={`navItems-item`}>
            {sanitizeUppercase('Επικοινωνία')}
          </Link> */}
        </div>
      )}
    </div>
  );
};
