import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useAxios} from '../../hooks/useAxios';
import config from '../../config/config.json';
import hero from '../../assets/images/hero_bg.jpg';
import {setDescription, setExpandFooter, setTitle} from '../../store/app/events';

import './Tomeis.scss';

export const Tomeis = () => {
  const {slug} = useParams();
  const {data, error} = useAxios(`${config.apiURL}/tomeis-dikaiou/${slug}`, 'GET', null, slug);
  const [tomeas, setTomeas] = useState<any>();

  useEffect(() => {
    setTomeas(data);
  }, [slug, data]);

  useEffect(() => {
    setExpandFooter(false);
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.tomeasDikaiou.metaTitle);
      setDescription(data.tomeasDikaiou.metaDescription);
    }
  }, [data]);

  return (
    <div className="page fadeIn">
      {data && slug && (
        <div className="page-header" style={{backgroundImage: `url('${hero}')`}}>
          <div className="container">
            <h1 className="page-header-title">Τομείς Δικαίου</h1>
          </div>
        </div>
      )}
      {data && slug && (
        <div className="container">
          <div className="inner">
            <h1>{data.tomeasDikaiou.title} </h1>
            <div className="text" dangerouslySetInnerHTML={{__html: data.tomeasDikaiou.text}} />
          </div>
        </div>
      )}
    </div>
  );
};
