import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {Loader} from '../Loader';
import {setIsMobile} from '../../store/app/events';

import {Version} from '../';

import './Layout.scss';
import useWindowSize from '../../hooks/useWindowSize';
import {useEffect} from 'react';
import {Banner} from '../Banner';
export const Layout = () => {
  const appStore = useStore($appStore);
  const {width} = useWindowSize();

  useEffect(() => {
    if (width < 1025) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);
  return (
    <div
      className={`layout${appStore.expandFooter ? ' expandFooter' : ''}${appStore.isLoading ? 'loading' : ''}  ${
        appStore.isMobile ? 'isMobile' : ''
      } `}
    >
      <Header />
      <div className={`main fadeIn`}>
        <Outlet />
      </div>
      {!appStore.isLoading && <Footer />}
      {appStore.isLoading && <Loader />}
      {/* <Version /> */}
      <Banner />
    </div>
  );
};
