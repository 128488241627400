import hero from '../../../assets/images/hero_bg.jpg';

interface IHero {
  data: any;
}

export const Hero = (props: IHero) => {
  const {data} = props;
  return (
    <div className="hero fadeIn" style={{backgroundImage: `url('${hero}')`}}>
      <div className="hero-inner container">
        <div className="hero-content">
          <h1 className="hero-content-title" dangerouslySetInnerHTML={{__html: data.title}} />
          <h2 className="hero-content-subtitle">{data.subtitle}</h2>
          <div className="hero-content-text" dangerouslySetInnerHTML={{__html: data.text}} />
        </div>
      </div>
    </div>
  );
};
