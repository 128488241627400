import config from '../../config/config.json';
import './Banner.scss';

export const Banner = () => {
  return (
    <div className="banner">
      <a href={`${config.apiURL}/assets/afisa-b-kyklos.pdf`} target="_blank" title="ΕΠΑνΕΚ 2014-2020" rel="noreferrer">
        <img src={`${config.apiURL}/assets/EKT180X150.png`} alt="ΕΠΑνΕΚ 2014-2020" />
      </a>
    </div>
  );
};
