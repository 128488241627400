import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Nav} from './components';
import {BurgerMenu} from './components/BurgerMenu';

import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';

import './Header.scss';

export const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const appStore = useStore($appStore);
  const [direction, setDirection] = useState('up');
  let oldScrollY = 0;

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection('down');
    } else {
      setDirection('up');
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  useEffect(() => {
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 60) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
  }, []);

  return (
    <>
      <header className={`${isCollapsed ? 'isCollapsed' : ''}`}>
        <div className="container">
          <Link to="/" className="logo" title="Δημήτρης Νιάφας - Αρχική σελίδα">
            <svg className="icon">
              <use xlinkHref="#logo" />
            </svg>
          </Link>
          <Nav />
          <BurgerMenu />
        </div>
      </header>
      <div className={`mobileNav ${appStore.navIsOpen ? 'navIsOpen' : ''}`}>
        <Nav />
      </div>
    </>
  );
};
