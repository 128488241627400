import {AppDomain} from './domain';
import {Language} from './types';

export const setLanguage = AppDomain.createEffect<Language, Language>();
export const setNavIsOpen = AppDomain.createEvent<boolean>();
export const setFooterIsDark = AppDomain.createEvent<boolean>();
export const setIsMobile = AppDomain.createEvent<boolean>();
export const setIsLoading = AppDomain.createEvent<boolean>();
export const setExpandFooter = AppDomain.createEvent<boolean>();
export const setIsDark = AppDomain.createEvent<boolean>();
export const setLanguages = AppDomain.createEvent<any>();
export const setCurrentPage = AppDomain.createEvent<string>();
export const setTitle = AppDomain.createEvent<string>();
export const setDescription = AppDomain.createEvent<string>();
