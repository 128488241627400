import {useState} from 'react';
import './BiografikoSection.scss';
interface IBiografikoSection {
  data: any;
}

export const BiografikoSection = (props: IBiografikoSection) => {
  const {data} = props;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="biografikoSection fadeIn">
      <div className="container">
        <div className="inner">
          <h2 className="biografikoSection-title">{data.teaserTitle}</h2>
          <div className="biografikoSection-text leadText" dangerouslySetInnerHTML={{__html: data.leadText}} />
          <div
            className={`biografikoSection-text shortText ${isOpened ? 'isVisible' : ''}`}
            dangerouslySetInnerHTML={{__html: data.shortText}}
          />
          <div className="biografikoSection-footer">
            <div onClick={() => setIsOpened(!isOpened)} className="biografikoSection-footer-link">
              {!isOpened ? 'περισσότερα' : 'κλείσιμο'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
